p {
    margin-bottom: 2px
}

.ql-toolbar.ql-snow {
    border: 1px solid #d9d9d9;
    border-radius: 8px 8px 0 0;
}

.ql-container.ql-snow {
    border: 1px solid #d9d9d9;
    border-radius: 0 0 8px 8px;
}
